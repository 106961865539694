// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/jon/src/SolipsisDev/store-front/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-pages-about-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-thanks-tsx": () => import("/home/jon/src/SolipsisDev/store-front/src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/jon/src/SolipsisDev/store-front/.cache/data.json")

